import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactForm from "../components/global/contact-form/ContactForm"
import SeparatorLine from "../components/global/contact-form/SeparatorLine"

const Branding = () => (
  <Layout>
    <Seo title="Branding NSC" />
    <h1 className="text-red">Freelance Web Developer</h1>
    <div className="card card--center-text flex-col-center">
      <h2 className="center-text">Web Development Services</h2>
      <h3 className="center-text">Charges</h3>
      <p className="center-text">
        I charge £24.99 per hour flat rate including VAT. Cheaper than any UK
        agency. For larger projects I can give an estimate.
      </p>
      <div className="flex-col-center">
        <h3 className="center-text">Bugs</h3>
        <p className="center-text">
          Most websites and applications have bugs, that's just the nature of
          the web these days as we use so many libraries not everything stays in
          sync all the time. There are a lot of bugs that don't reach the top of
          your priority list so don't get fixed straight away, or you never
          reach them because of other priorities. Maybe you have issues with
          your site not looking right on certain devices, performance errors or
          other errors you just can't resolve?
        </p>
        <p className="center-text">
          I can fix these errors bugging you and your customers.
        </p>
        <h3 className="center-text">WordPress</h3>
        <p className="center-text">
          I'm experienced in WordPress at building landing pages, responsive
          design, custom plugins or widgets. WordPress is amazing if used
          correctly but can be lot of work if not organised in the right manner.
        </p>
        <p className="center-text">
          I can help you streamline your site so you get the best out of it.
        </p>
        <h3 className="center-text">Web Applications</h3>
        <p className="center-text">
          Maybe you need a web application for your business or extra features
          added to your current application.
        </p>
        <p className="center-text">
          I'm experienced in building applications that help solve business
          problems so together we can build the tools to help your business
          grow.
        </p>
      </div>
      <SeparatorLine />
      <h2 className="center-text">My Experience</h2>
      <p className="center-text">
        These are the the Languages and frameworks I'm experienced in. If it's
        not listed here contact me and I may be able to point you in the right
        direction.
      </p>
      <div className="flex-col-center">
        <h3 className="center-text">HTML</h3>
        <p className="center-text">
          I can build clean structured, accessible web pages for you
        </p>
        <h3 className="center-text">CSS</h3>
        <p className="center-text">
          I'm pretty good at organising a web page, and simple animations or
          transitions for your site. If you want anything super fancy I'd be
          happy to find someone who could do this for you.
        </p>
        <h3 className="center-text">Javascript</h3>
        <p className="center-text">All front end work in Javascript.</p>
        <h3 className="center-text">PHP</h3>
        <p className="center-text">
          I'm experienced in Object Oriented Programming
        </p>
        <h3 className="center-text">Laravel</h3>
        <p className="center-text">
          A beautiful and modern Web Application framework I use to build Web
          Applications
        </p>
        <h3 className="center-text">Livewire</h3>
        <p className="center-text">
          Great for letting me build you a component based front end application
        </p>
        <h3 className="center-text">React</h3>
        <p className="center-text">
          Great for building front facing component based applications like
          Instagram and Facebook
        </p>
        <h3 className="center-text">Gatsby</h3>
        <p className="center-text">
          Great framework for custom static sites made for performance and SEO
          which is why I built this site in Gatsby.
        </p>
      </div>
    </div>
    <ContactForm />
  </Layout>
)

export default Branding
